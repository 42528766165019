<template>
    <div class="row justify-content-center" v-if="loading">
        <div class="col-sm-5 text-center pt-2">
            <font-awesome-icon icon="spinner" spin size="3x"></font-awesome-icon>
            <p class="text-center my-1">Loading...</p>
        </div>
    </div>
    <div v-else>
        <div v-if="!access">
            <NotFound />
        </div>
        <div v-else>
            <div class="pb-2 mb-3 border-bottom">
                <h2>Apply Leave Encash</h2>
            </div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <router-link :to="{name:'my-leaves'}">My Leaves</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <router-link :to="{name:'leave-encash'}">Leave Encash</router-link>
                    </li>
                    <li class="breadcrumb-item active">Apply</li>
                </ol>
            </nav>
            <br />
            <div class="container-fluid">
                <div v-if="api_error">
                    <b-alert
                        :show="alertDismissCountDown"
                        dismissible
                        variant="danger"
                        @dismissed="alertDismissCountDown=0"
                        @dismiss-count-down="alertCountDownChanged"
                    >{{api_error}}</b-alert>
                </div>
                <b-row>
                    <b-col lg="3" class="mb-1rem">
                        <span class="h5 font-weight-bold">Remaining Leaves: {{ remaining_leaves.leaveBalance }}</span> 
                    </b-col>
                    <b-col lg="3" class="mb-1rem">
                        <span class="h5 font-weight-bold">Applied Leaves: {{ remaining_leaves.applied_leaves }}</span> 
                    </b-col>
                    <b-col lg="4" class="mb-1rem">
                        <span class="h5 font-weight-bold">Max Encash Leaves: {{ remaining_leaves.leaves_encashed }}</span>
                    </b-col>
                </b-row><br>
                <div class="col-md-8">
                    <form v-on:submit.prevent="applyLeaveEncash()">
                        <div class="form-group row">
                            <label class="col-md-4">
                                No of leaves to be encashed
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-8">
                                <input
                                type="number"
                                class="form-control"
                                v-model="leave.value"
                                autocomplete="off"
                                />
                            </div>

                        </div>

                        <div class="form-group row" style="float:right;">
                            <div class="offset-xs-3 col-xs-9">
                                <button
                                    type="button"
                                    class="btn btn-primary text-right ma-10px"
                                    v-on:click="applyLeaveEncash()"
                                >Submit</button>
                            </div>
                            <div class="offset-xs-3 col-xs-9">
                                <button
                                    type="button"
                                    @click="$router.go(-1)"
                                    class="btn btn-secondary ma-10px"
                                >Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            leave: {
                value: ""
            },
            api_error: "",
            access: true,
            loading: true,
            enableLeaveEncash: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            max_encashed_leaves: '',
            remaining_leaves: '',
            start_date:'',
            end_date:'',
            current_date:'',
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

        };
    },
    mounted() {
        const leaves_encash_months = this.$store.getters.getAppSetting("payroll", "leave_encash_month");
        const current_date = new Date();
        const current_month = this.months[current_date.getMonth()];
        this.enableLeaveEncash = leaves_encash_months.includes(current_month);
        this.getEmployee();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getEmployee: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            this.axios.get(this.$api.get_employees, query).then(response => {
                let encash_leaves = response.data.data.leave_group.leaves_encashed ? response.data.data.leave_group.leaves_encashed : 0;
                if(!this.enableLeaveEncash || encash_leaves == 0) {
                    this.access = false;
                }
                this.getRemainingLeaves();
                this.loading = false;
                this.updateProgressBar(true);
            })
            .catch(err => {
                this.loading = false;
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },
        
        applyLeaveEncash: function() {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            this.leave.start_date = this.start_date;
            this.leave.end_date = this.end_date;
            query['params'] = {};
            this.axios.post(this.$api.leaves_encashed_apply, this.leave, query).then(() => {
                this.updateProgressBar(true);
                this.$router.push({name: 'leave-encash'});
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        getRemainingLeaves: function() {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            this.axios.get(this.$api.get_remaining_leaves, query).then(response => {
                this.remaining_leaves = response.data.data;
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        }
      
    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "../../../assets/css/custom.scss";

.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}
</style>